import React, { useEffect, useState,useRef } from 'react';
import AppLayout from '../../components/layout';
import { connect } from "react-redux"
import axios from 'axios'
import { sampleFunction} from "../../actions/sampleAction";
import { changeProfile} from "../../actions/commonAction";
import { Routes } from '../../utils/route'
import "./settings.css"
import { Select,Spin,Button,Switch,Tag,Form,Input,Progress,Col,Row, Divider, message, Radio } from "antd";
import API from "../../api"
import moment from 'moment';
import { useDispatch } from "react-redux";
import {toggleChange} from "../../actions/toggleAction";
import { 
	CloseOutlined, CheckOutlined
} from '@ant-design/icons';

const Report = props => {
  const dispatch = useDispatch();
  const [profileImage,setProfileImage]=useState('')
  const [certificateImage,setCertificateImage]=useState('')
  const [invoiceImage,setInvoiceImage]=useState('')
  const {getClientDetails,updateSettings,preWashToggle} = API;
  const [loading, setLoading] = useState(true)
  const [load,setLoad]=useState(true)
  const [clientId, setClientId] = useState('')
  const [togl,setTogl]=useState()

  useEffect(() => {
   const client_id= JSON.parse(localStorage.getItem('data'))
   setClientId(client_id?.client_id)
   getClientDetails(client_id?.client_id).then((res)=>{
    const data=res.data.data
    setProfileImage(data.logo)
    setCertificateImage(data.washout_certification_logo)
    setInvoiceImage(data.invoice_logo)
    setLoad(false)
    data.prewash_check===0?setTogl("Disable"):setTogl("Enable")
   })
  }, [])
 
  useEffect(() => {
    const callback = (val) => {
      if (val === true) {
        setLoading(false);
      }
    }
    Routes('manager', callback)
  }, [])
  const  buttonChange=(checked)=>{
    // setTogl(checked ? "Enable" : "Disable")
    // dispatch(toggleChange(togl))
    preWashToggle({prewash_check:checked}).
      then((res) => {
        // let chkd=res.data.data.prewash_check
        setTogl(checked ? "Enable" : "Disable")
      }).catch((error) => {
        
      })
  }
  if(!loading){
  return (
    <AppLayout>
      <h3>Settings </h3>
      {!load ?
        <>
        <div className="row">
         <ImageBox changeProfile={(e)=>props.changeProfile(e)} clientId={clientId} data={profileImage} setData={setProfileImage} name="logo" title="Business or Profile Image"/>
         <ImageBox clientId={clientId} data={certificateImage} setData={setCertificateImage} name="washout_certification_logo" title="Certificate Image"/>
         <ImageBox clientId={clientId} data={invoiceImage} setData={setInvoiceImage} name="invoice_logo" title="Invoice Image"/>
        </div>
        <div className="wash_setting_switch">
        <h3>Pre Wash</h3>
        <Switch checkedChildren="Enable" unCheckedChildren="Disable" checked={togl==="Enable"?true:false} onChange={buttonChange} />
        </div>
        <div>
          <QuickBook/>
        </div>
        </>
        :
        <div className="spinner-main"><Spin /></div>}
    </AppLayout>
  )
}
else{
  return(<div className="spinner-main"><Spin/></div>)
}
}
const mapStateToProps = state => ({
  darkMode: state.data.isDarkMode,
})

export default connect(mapStateToProps, { sampleFunction,changeProfile })(Report)


const QuickBook=()=>{
  const [form] = Form.useForm();
  const [loading,setLoading]=useState(false)
  const [formData,setFormData]=useState({clientId:null,clientSecret:null,active_status:false})
  useEffect(()=>{
    let data = JSON.parse(localStorage.getItem('data'));
    const _formData={clientId:data.quick_book_client_id,clientSecret:data.quick_book_client_secret,active_status:data.quick_book_active_status===0?false:true}
form.setFieldsValue(_formData)
setFormData({...formData,..._formData})
  },[])
  const {quickBook,quickBookStatus} = API;
  const onFinish=(value)=>{
    setLoading(true)
    quickBook(value).then((res)=>{
    if(res.data.success){
      let _data = JSON.parse(localStorage.getItem('data'));
      _data={..._data,quick_book_client_id:value.clientId,quick_book_client_secret:value.clientSecret,quick_book_active_status:1}
      localStorage.setItem('data', JSON.stringify(_data));
      const _formData={...formData,active_status:1}
      setFormData({...formData,..._formData})
      message.success({ content: res.data.message, duration: 3 });
    }else{
      message.error({ content: res.data.message, duration: 3 });
    }
    setLoading(false)
    }).catch((err)=>{
      setLoading(false)
      message.error({ content: `${err?.response?.data.message}`, duration: 3 });
    })
  }
const changeStatus=(value)=>{
  setLoading(true)
  quickBookStatus(value===false?0:1).then((res)=>{
    if(res.data.success){
      message.success({ content: res.data.message, duration: 3 });
      setFormData({...formData,active_status:value})
      let _data = JSON.parse(localStorage.getItem('data'));
      _data={..._data,quick_book_active_status:value?1:0}
      localStorage.setItem('data', JSON.stringify(_data));
    }else{
      message.error({ content: res.data.message, duration: 3 });
    }
    setLoading(false)
  }).catch((err)=>{
    message.error({ content: `${err?.response?.data.message}`, duration: 3 });
    setLoading(false)
  })
}
return(
  <div style={{marginTop:30}}>
    <Divider/>
    <h3>QuickBook Integration</h3>
  <Form
  form={form}
  id="quickBook"
  name="quickBook"
  onFinish={onFinish}
>
<Row gutter={24}>
  <Col span={12}>
  <Row gutter={24}>
<Col span={24}>
<Form.Item
    rules={[{
      required: true,
      message: 'Please Enter Client ID',
    }]}
                  labelCol={{ span: 24 }}
                  wrapperCol={{ span: 24 }}
                  name="clientId"
                  label={<span>Client ID</span>}
                >
                  <Input disabled={formData.clientId}/>
                  </Form.Item>
  </Col>
  <Col xs={{ span: 24}}>
  <Form.Item
                  labelCol={{ span: 24 }}
                  wrapperCol={{ span: 24 }}
                  name="clientSecret"
                  rules={[{
                    required: true,
                    message: 'Please Enter Client Secret',
                  }]}
                  label={<span>Client Secret</span>}
                >
                  <Input  disabled={formData.clientSecret}/>
                  </Form.Item>
  </Col>
  <Col xs={{ span: 24}}>
   {(!formData.clientId&&!formData.clientSecret)?<Form.Item>
               <Button loading={loading}  type="primary"    htmlType="submit">Integrate</Button>
                  </Form.Item>
                :
                <span className="ml-3 text-dark">
                <Switch disabled={loading} 
                onClick={(v) => changeStatus(v)} 
                checked={formData?.active_status} 
                checkedChildren={< CheckOutlined />} 
                unCheckedChildren={<CloseOutlined />} />
            <Tag className ="text-capitalize ml-2"  
            color={formData?.active_status?'cyan':'red'}>{formData?.active_status?'Active':'Inactive'}
            </Tag></span>
                
                }
  </Col>
  </Row>
  </Col>
  </Row>
  </Form>
  </div>
)
}




const ImageBox=({data,title,name,setData,clientId,changeProfile})=>{
  var CancelToken = axios.CancelToken;
  const {updateSettings} = API;
  var cancel=axios.isCancel;
  const cancelFileUpload=useRef(null)
  const [update,setUpdate]=useState(0)
const setAttachment=(_data)=>{
  const option={
      onUploadProgress:(progressEvent)=>{
          const {loaded,total}=progressEvent;
          let percent = Math.floor((loaded*100)/total)
       
          if(percent<=100){
                      setUpdate(percent)
                      // setAttachment(attach)
          }
      },
      cancelToken: new CancelToken(function executor(c) {
          // An executor function receives a cancel function as a parameter
          cancelFileUpload.current = c;})
  }
  const formData=new FormData()
  formData.append('file',_data.target.files[0])
  formData.append('type',name)
  formData.append('client_id',clientId)
  updateSettings(formData,option).then((res)=>{
      setUpdate(0)
      // toast.success("File uploaded successfully",{autoClose: 3000})
      const data=JSON.parse(localStorage.getItem('data'))
      const newdata={...data,[name]:res.data.data[0].file}
      if(changeProfile){
        changeProfile(res.data.data)
      }
      localStorage.setItem('data',JSON.stringify(newdata))
      setData(res.data.data[0].file)
  }).catch((err)=>{
      if(cancel(err)){
          // toast.error(err.message,{autoClose: 3000})
      }
      setUpdate(0)
  })
}

const cancelUpload=()=>{
  
  if(cancelFileUpload.current){
      cancelFileUpload.current("File upload canceled")
  }
}
  return(
    <div className="col-lg-4 col-sm-6 col-xs-12">
     <div className={`image_upload ${update!==0&&'image_progress'}`}>
         {update?<Progress type="circle" percent={update} />:<label  htmlFor={name} style={{cursor:'pointer'}}>
             <img width="100%" src={data} className="image"  align="middle" alt={""}/>
             <div className="settings-title-box">
             <span className="settings-title-upload">Upload</span>
             <span className="settings-title">{title}</span>
             <div className="upload-now">Upload Now</div>
             </div>
      </label>}
      
      {update!==0&&<Button style={{marginTop:20,color:'red'}} onClick={()=>cancelUpload(name)}>Cancel</Button>}
      </div>
      <input  hidden onClick={e => (e.target.value = null)} id={name}  type="file" accept="image/x-png,image/jpeg" onChange={(e)=>setAttachment(e,name)}/>
     </div>
  )
}